import {Injectable} from '@angular/core';
import {filter, first} from 'rxjs/operators';
import {LinkService} from '@core/services/link.service';
import {HomeRoutes} from '@app/routes/home.routes';
import {StateService} from '@core/services/state.service';
import {TypeService} from '@core/services/type.service';
import {PageType} from '../../../../page-type';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import {UserService} from '@core/services/user.service';
import {AdService} from '@lib-modules/ads/services/ad.service';
import {AnalyticsService} from '@utils/services/analytics/analytics.service';
import {UrlService} from '@utils/services/url/url.service';
import {SportTypeService} from '@api/services/sport-type/sport-type.service';
import {SportType} from '@lib/models/sport-type/sport-type';
import {WebviewService} from '../../../../../projects/shared/utils/src/lib/services/webview/webview.service';
import {BehaviourAnalyticsService} from '../behaviour-analytics/behaviour-analytics.service';
import {SessionStorageKey} from '@utils/storage/storage-keys';

interface ILinks {
  name: string;
  navigateTo: string;
  matchUrls?: string[];
  ignoreUrls?: string[];
  exactMatch?: boolean;
  isAbsolute?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {

  public links: ILinks[];
  public homeLink = '';
  public newsLink = '';
  public gamesLink = '';
  public videosLink = '';
  public favouritesLink = '';
  public votingLink = '';
  public casinoCupLink = '';
  public shopLink = '';
  public useRelative;
  public redirectSource: string;
  public prevNavUrl: string;
  public lastNavEvent: NavigationEnd;
  private loggedIn = false;
  // redirectSource can be set by aws lambda redirects e.g. /votings redirects to /news/:id?redirect-source=votings
  // If this is set use it to determine if a tab is active or not

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private adService: AdService,
    private analyticsService: AnalyticsService,
    private sportTypeService: SportTypeService,
    private userBehaviourAnalytics: BehaviourAnalyticsService,
  ) {
  }

  public init(): void {
    this.sportTypeService.updateSelectedSport();
    this.userService.currentUserSubject.subscribe((user) => {
      this.loggedIn = !!user;
      this.updateLinks();
    });
    this.listenToRouterEvents();
    this.route.queryParamMap.subscribe((paramMap) => {
      if (paramMap.has('redirect-source')) {
        this.redirectSource = paramMap.get('redirect-source');
      }
    });
  }

  public updateLinks(): void {
    this.useRelative = TypeService.pageType === PageType.Home;
    this.homeLink = LinkService.createFullLink('/');
    this.videosLink =
      SportTypeService.selectedSportType === SportType.Handball
        ? LinkService.createFullLink(HomeRoutes.handball.relative.videos)
        : LinkService.createFullLink(HomeRoutes.videos.root);
    this.favouritesLink = LinkService.createFullLink(HomeRoutes.favourites);
    this.votingLink = UrlService.Web + '/votings';
    this.shopLink = LinkService.createFullLink(HomeRoutes.shop);
    this.casinoCupLink = UrlService.Web + '/casinocup';
    // const totrLink = LinkService.createFullLink(HomeRoutes.teamOfTheRound);
    // const jobsLink = LinkService.createFullLink(HomeRoutes.jobsLink);
    this.setNewsLink();
    this.setGamesLink();
    const start =
      TypeService.pageType === PageType.Home ? '/' : LinkService.getHomeLink();
    const isAbsolute = TypeService.pageType !== PageType.Home;

    this.links = [
      {name: 'Home', navigateTo: start, isAbsolute},
      {
        name: 'Videos',
        navigateTo: this.videosLink,
        matchUrls: [
          LinkService.createFullLink(HomeRoutes.videos.root),
          LinkService.createFullLink(HomeRoutes.handball.relative.videos),
        ],
        isAbsolute: true,
      },
      {
        name: 'Spiele',
        navigateTo: this.gamesLink,
        matchUrls: [
          LinkService.createFullLink(HomeRoutes.games.root),
          LinkService.createFullLink(HomeRoutes.games.rootPersonal),
        ],
        isAbsolute,
      },
      {
        name: 'Votings',
        navigateTo: this.votingLink,
        isAbsolute: true,
      },
      {
        name: 'News',
        navigateTo: this.newsLink,
        matchUrls: [LinkService.createFullLink(HomeRoutes.news.root)],
        isAbsolute,
      },
      {name: 'Favoriten', navigateTo: this.favouritesLink, isAbsolute},
      // { name: "Casino Cup", navigateTo: this.casinoCupLink, isAbsolute: true },
      /*
      commented out in favour of WM 2022 and Herbstvotings
      {
        name: 'Elf der Runde',
        navigateTo: totrLink,
        isAbsolute,
        matchUrls: [ totrLink ]
      },
      {
        name: 'Jobs',
        navigateTo: jobsLink,
        isAbsolute: true,
        matchUrls: [ jobsLink ]
      },*/
    ];
  }

  public setNewsLink(): void {
    const teamNews = `${HomeRoutes.news.root}${
      this.loggedIn ? '/' + HomeRoutes.news.personal : ''
    }`;
    this.newsLink = LinkService.createFullLink(teamNews);
  }

  public setGamesLink(): void {
    this.gamesLink = LinkService.createFullLink(
      this.loggedIn ? HomeRoutes.games.rootPersonal : HomeRoutes.games.root,
    );
  }

  public newsActive(): boolean {
    const uri = this.redirectSource || this.router.url;
    return uri.startsWith('/news');
  }

  public gamesActive(): boolean {
    const uri = this.redirectSource || this.router.url;
    return uri.startsWith('/spiele') || uri.startsWith('/meine-spiele');
  }

  public votingActive(): boolean {
    const uri = this.redirectSource || this.router.url;
    return uri.includes('votings');
  }

  private listenToRouterEvents(): void {
    this.router.events.subscribe((navEvent: RouterEvent) => {
      this.handleUrlEvent(navEvent);

      if (navEvent instanceof NavigationStart) {
        this.handleNavigationStart(navEvent);
      }
      if (navEvent instanceof NavigationEnd) {
        this.handleNavigationEnd(navEvent);
      }
    });
  }

  private handleUrlEvent(navEvent: RouterEvent) {
    if (!navEvent.url) {
      return;
    }
    this.adService.isHomePage = TypeService.pageType === PageType.Home && navEvent?.url === '/';
    if (StateService.isBrowser) {
      const prevUrl = sessionStorage.getItem(SessionStorageKey.CurrentUrl);
      if (!navEvent.url.includes(prevUrl)) {
        this.prevNavUrl = prevUrl;
      }
    }
  }

  private handleNavigationStart(navEvent: NavigationStart) {
    this.redirectSource = null;
    if (StateService.isWebview && !WebviewService.allowNavigation) {
      // Prevent routerLinks from navigation
      this.router.navigateByUrl(this.lastNavEvent.urlAfterRedirects, {replaceUrl: true});
      return;
    }
    if (!this.lastNavEvent) {
      return;
    }
    // Do not destroy if coming from fragment route
    if (!this.lastNavEvent.urlAfterRedirects?.includes('#')) {
      this.adService.destroyAllAds();
    }
  }

  private handleNavigationEnd(navEvent: NavigationEnd): void {
    const oldSportType = SportTypeService.selectedSportType;
    this.sportTypeService.updateSelectedSport();
    if (oldSportType !== SportTypeService.selectedSportType) {
      this.updateLinks();
    }

    this.lastNavEvent = navEvent;

    if (StateService.isBrowser) {
      sessionStorage.setItem(SessionStorageKey.CurrentUrl, this.lastNavEvent.url);
    }

    this.userBehaviourAnalytics.pageVisited();

    const urlPrefix = TypeService.pageType === PageType.Home ? '' : `/${TypeService.pageType}`;
    this.analyticsService.onRouteChange(urlPrefix + navEvent.urlAfterRedirects);

    if (StateService.isBrowser && UrlService.UseProdUrls) {
      const url = new URL(window.location.href);
      url.searchParams.set('prod', 'true');
      history.replaceState({}, document.title, url.toString());
    }
  }
}
