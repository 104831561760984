import {Injectable} from '@angular/core';
import {HttpService} from '../http.service';
import {DefaultResponse, IDefaultListResponse, IDefaultResponse, IDefaultResponseT} from '@api/models/default-response/response';
import {IUser} from '@api/models/user/user';
import {REPORTER_MESSAGING_ROUTE, USERS_ROUTE, USERS_ROUTE_NEW} from '@api/http/http-routes';
import {IReporterMessagingStatus} from '@api/models/reporter-messaging/reporter-messaging-status';
import {Team} from '@api/models/team/team';
import {League} from '@api/models/league/league';
import {FavouritesType} from '@api/models/favourites/favourites-card';
import {
  DefaultLeagueNotificationSettings, DefaultNotificationSettings,
  DefaultTeamNotificationSettings,
  ILeagueNotificationSettings
} from '@api/models/user/user-notification-settings';
import {FollowEntityType} from '@api/models/user/user-follow';
import { UserPermission, UserPermissions } from '../../models/user/user-permissions';

type IMessagingResponse = IDefaultResponseT<IReporterMessagingStatus>;

enum PermissionEntity {
  team = "team",
  league = "league"
}

@Injectable({
  providedIn: 'root'
})
export class UserHttpService {

  constructor( private http: HttpService ) { }

  public async putUpdateUser(userId: string, data: Partial<IUser>): Promise<IDefaultResponse> {
    return this.http.put<IDefaultResponse>([USERS_ROUTE, userId], data).toPromise();
  }

  public async getUser(userId?: string, cache?: boolean): Promise<IUser> {
    return await this.http.get<IUser>([USERS_ROUTE, userId], null, cache);
  }

  public async getUserPermissions(userId: string): Promise<UserPermissions> {
    const response = await this.http.get<DefaultResponse>(['user-permissions/user', userId]);
    return new UserPermissions(response.data);
  }

  public async setUserPermission(
    userId: string,
    permission: UserPermission<unknown>
  ): Promise<DefaultResponse> {
    const data = {
      entity: permission.entity,
      entityid: permission.entityId,
      ...permission
    }
    return await this.http.patch<DefaultResponse>(['user-permissions/user', userId], data).toPromise();
  }

  public async deleteUserPermission(userId: string, permission: UserPermission): Promise<void> {
    const data = {
      entity: permission.entity,
      entityid: permission.entityId,
    }
    await this.http.delete(['user-permissions/user', userId], [], data).toPromise();
  }

  public async postDigestLoginUser(userId: string, timestamp: number, digest: string): Promise<IUser> {
    const data = {
      userid: userId,
      timestamp,
      digest
    };
    return await this.http.post<IUser>([USERS_ROUTE, 'digestlogin'], data).toPromise();
  }

  public async getInviteByUserAndType(status: 'open' | 'accepted' = 'open'): Promise<IDefaultListResponse<any>> {
    return await this.http.get<IDefaultListResponse<any>>([USERS_ROUTE_NEW, 'my', 'invites', status]);
  }

  public async getUserMessagingStatus(
    userId: string, token: string, tickerSearch = ''
  ): Promise<IMessagingResponse> {
    return await this.http.get<IMessagingResponse>([
      REPORTER_MESSAGING_ROUTE, 'subscription', userId, token, tickerSearch
    ]);
  }

  public async setUserMessagingStatus(
    userId: string, token: string, enabled: boolean, tickerSearch = ''
  ): Promise<IDefaultResponse> {
    return await this.http.put<IDefaultResponse>([
      REPORTER_MESSAGING_ROUTE, 'subscription', userId, token, tickerSearch
    ], { enabled }).toPromise();
  }

  public async getFavoriteTeams(): Promise<IDefaultListResponse<Team>> {
    return this.getFavoritesByType(FavouritesType.Teams);
  }

  public async getFavoriteLeagues(): Promise<IDefaultListResponse<League>> {
    return this.getFavoritesByType(FavouritesType.Leagues);
  }

  public async postResendVerification(email: string): Promise<IDefaultResponse> {
    return this.http.post<IDefaultResponse>(
      [USERS_ROUTE, 'emailverification', 'resend'], { email }
    ).toPromise();
  }

  public async followEntity(entityType: FollowEntityType, entityId: string,
                            body?: ILeagueNotificationSettings): Promise<IDefaultResponse> {
    if (!body) {
      body = DefaultNotificationSettings;
      if (entityType === FollowEntityType.League) {
        body = DefaultLeagueNotificationSettings;
      }
      if (entityType === FollowEntityType.Team) {
        body = DefaultTeamNotificationSettings;
      }
    }

    return await this.http.put<IDefaultResponse>(
      [USERS_ROUTE, 'notification-settings', `${entityType}`, entityId], body
    ).toPromise();
  }

  public async unfollowEntity(entityType: FollowEntityType, entityId: string): Promise<IDefaultResponse> {
    return await this.http.delete(
      [USERS_ROUTE, 'notification-settings', `${entityType}`, entityId]
    ).toPromise();
  }

  public async patchSetUserVerifiedReporter(userId: string, verified: boolean): Promise<IDefaultResponse> {
    return this.http.patch<IDefaultResponse>([USERS_ROUTE_NEW, 'verified-reporter', userId],
      { verified_reporter: verified }).toPromise();
  }

  private async getFavoritesByType(favouritesType: FavouritesType): Promise<IDefaultListResponse<any>>  {
    return this.http.get([USERS_ROUTE_NEW, 'my', 'favourites', favouritesType]);
  }
}
